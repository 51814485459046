.mainContainer {
    margin: 10pt;
    /* background-color: antiquewhite; */
}

.mainContainer p {
    margin-top: 5pt;
}

.mainContainer p span {
    font-weight: bold;
}
